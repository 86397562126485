<template>
    <div class="rounded-lg pb-5 relative">
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-2">
                    <label class="text-gray-400">
                        金流編號
                        <el-input v-model="filterOpts.transaction_log_id" size="small" clearable> </el-input>
                    </label>
                </div>
                <div class="col-span-3">
                    <label class="text-gray-400">
                        提領人 手機
                        <div class="flex">
                            <div class="phone-select">
                                <el-select v-model="filterOpts.phone_country" size="small" placeholder="请选择">
                                    <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-input v-model="filterOpts.phone" size="small" clearable> </el-input>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-span-2">
                    <label class="text-gray-400">
                        事件
                        <el-select v-model="filterOpts.reason" size="small" class="w-full" clearable>
                            <el-option v-for="(event, index) in payEventOptions" :key="index" :label="event.label" :value="event.value">
                                {{ event.label }}
                            </el-option>
                        </el-select>
                    </label>
                </div>
                <div class="col-span-3">
                    <label class="text-gray-400">
                        預計撥款日期 範圍
                        <el-date-picker
                            v-model="dateInputs"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="col-span-2 flex items-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div v-if="showTotalArea" class="total-board">
            <div class="left">月結款項 金額：${{ totalCount.salary | formatCurrency }}</div>
            <div class="right">立即提領 金額：${{ totalCount.immediate | formatCurrency }}</div>
        </div>
        <div v-if="hasInput" class="overflow-x-auto bg-white">
            <div>
                <table class="myTable">
                    <thead>
                        <tr>
                            <th>金流編號</th>
                            <th>提領人 暱稱</th>
                            <th>事件</th>
                            <th>金額</th>
                            <th>申請時間</th>
                            <th>預計撥款時間</th>
                            <th>查看</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                            <td>{{ item.transaction_log_id }}</td>
                            <td>{{ item.user.name }}</td>
                            <td>{{ item.reason | paymentReason }}</td>
                            <td>${{ item.amount | formatCurrency }}</td>
                            <td>{{ item.created_at | formatDateTime }}</td>
                            <td>{{ item.transaction_date | formatDate }}</td>
                            <td class="cursor-pointer" @click="goDetail(item.id)">
                                <i class="far fa-edit"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="justify-center mt-3 flex mb-3">
                    <MyPagination
                        :paginationData="paginationData"
                        :currentPage="currentPage"
                        @onPageSizeChange="pageSizeChange"
                        @onCurrentPageChange="currentPageChange" />
                </div>
            </div>
        </div>
        <div v-else class="show-limit">請輸入至少一項搜尋條件</div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 對帳相關 filters
import "./filterAccount";
// 對帳相關 configs
import accountConfigs from "@/config/accountConfig.js";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// init search Filter options
const defaultFilterOpts = {
    transaction_log_id: "",
    phone_country: "886",
    phone: "",
    status: [3], // 0(服務商或系統提出提領), 1(會計確認無誤), 2(匯出銀行檔案), 3(完成匯款), -1(發生錯誤)
    reason: "",
    transaction_date_start: "",
    transaction_date_end: "",
    sort_by: "transaction_date",
};
export default {
    name: "WithdrawRecord",
    components: {
        LoadingComponent,
        MyPagination,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const phone = this.filterOpts.phone ? `${this.filterOpts.phone_country}${this.filterOpts.phone}` : "";
            return {
                ...this.filterOpts,
                phone: phone,
                limit: this.paginationData.limit,
                page: this.currentPage,
                phone_country: "886",
            };
        },
        // 付款狀態 options
        payEventOptions() {
            const status = accountConfigs.payReasons;
            const result = [
                {
                    label: "不限",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 手機國碼
        phoneOptions() {
            return phoneOptions;
        },
        // totoal
        totalCount() {
            const result = {
                salary: 0,
                immediate: 0,
            };
            this.datasTotal.forEach((i) => {
                if (i.reason === "WITHDRAW") {
                    result.immediate += i.amount;
                } else {
                    result.salary += i.amount;
                }
            });
            return result;
        },
    },
    data() {
        return {
            firstIn: true,
            loading: false,
            datas: [],
            datasTotal: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            hasInput: false,
            showTotalArea: false,
            currentPage: 0,
            filterOpts: { ...defaultFilterOpts },
            dateInputs: [], // 時間範圍
        };
    },
    methods: {
        async getList() {
            this.loading = true;
            await this.getTotal();
            const { status, data } = await this.$api
                .SearchRemittancesAPI(this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.hasInput = true;
                if (this.dateInputs && this.dateInputs.length > 0) {
                    this.showTotalArea = true;
                } else {
                    this.showTotalArea = false;
                }
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        async getTotal() {
            this.loading = true;
            const req = { ...this.filterData };
            delete req.limit;
            delete req.page;
            const { status, data } = await this.$api
                .SearchRemittancesAPI(req)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datasTotal = [...data.data];
            }
        },
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        reset() {
            this.hasInput = false;
            this.showTotalArea = false;
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
        },
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 連結相關
        goDetail(paymentID) {
            this.$router.push({
                name: "stored_detail",
                params: {
                    paymentID,
                },
                query: {
                    pre: this.$route.name,
                },
            });
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.transaction_date_start = "";
                this.filterOpts.transaction_date_end = "";
                return;
            }
            this.filterOpts.transaction_date_start = val[0];
            this.filterOpts.transaction_date_end = val[1];
        },
        "filterOpts.phone"(val) {
            if (this.$isEmpty(val)) {
                return;
            }
            if (val[0] == 0 && val[1] == 9) {
                this.filterOpts.phone = val.substring(1);
                return;
            }
        },
    },
    activated() {
        if (!this.firstIn) {
            this.getList();
        }
        this.firstIn = false;
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}
.show-limit {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #757575;
    font-weight: 500;
}
.phone-select {
    width: 150px;
    margin-right: 5px;
}
.total-board {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    .left {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 50px;
        margin-right: 20px;
        background: white;
    }
    .right {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 50px;
        background: white;
    }
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
